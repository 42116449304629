export default class MegaMenuItem {
    constructor(el, lastItem) {
        this.$el = el;
        this.isLastItem = lastItem;
        this.$siteHeader = document.querySelector('[data-site-header]');
        this.$action = this.$el.querySelector('[data-megamenu-action]');
        this.$dropdown = this.$el.querySelector('[data-megamenu-item]');
        this.$navWrapper = document.querySelector('[data-desktop-navigation]');
        this.$focusableMenuItems = [
            ...this.$el.querySelectorAll('[role="menuitem"]'),
        ];
        this.$emptyMenu = this.$el.querySelector('[data-empty-menu]');
        this.$overlay = document.querySelector('[data-underlay]');
        this.addEventListeners();
        this.moveEmptyMenu();
    }

    addEventListeners() {
        this.$action.addEventListener('mouseenter', () => {
            this.$action.focus();
        });

        this.$action.addEventListener('mouseleave', () => {
            this.$action.blur();
        });

        this.$action.addEventListener('focusin', () => {
            this.open();
        });

        this.$overlay.addEventListener('mouseenter', () => {
            this.close();
        });
        this.$navWrapper.addEventListener('mouseleave', () => {
            this.close();
        });
    }

    open() {
        this.closeSiblingMenus();
        this.$dropdown.setAttribute('aria-expanded', 'true');
        this.$overlay.setAttribute('data-blur', 'true');
        this.$overlay.setAttribute('data-state', 'visible');
        this.$siteHeader.style.zIndex = '30';
    }

    close() {
        this.$dropdown.setAttribute('aria-expanded', 'false');
        this.$overlay.setAttribute('data-blur', 'false');
        this.$overlay.setAttribute('data-state', 'hidden');
    }

    closeSiblingMenus() {
        window.dispatchEvent(
            new CustomEvent('megamenu.opened', { detail: 'megamenu opened' })
        );
        window.addEventListener('megamenu.opened', () => {
            this.close();
        });
    }

    moveEmptyMenu() {
        if (this.$emptyMenu === null) {
            return;
        }
        this.$action.parentElement.style.position = 'relative';
    }
}
